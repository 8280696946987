<template>
  <v-container>
    <header-title text="Reportes"></header-title>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="300" tile>
          <v-list subheader two-line>
            <v-subheader inset>DTEs</v-subheader>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-check-outline </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Resumen DTEs</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action @click="summary('dte')">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="300" tile>
          <v-list subheader two-line>
            <v-subheader inset>Movimientos de Cuenta</v-subheader>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-check-outline </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Reporte Movimientos</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action @click="summary('mvnt')">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="300" tile>
          <v-list subheader two-line>
            <v-subheader inset>Libro de Compras</v-subheader>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-check-outline </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Libro de Compras</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action @click="summary('cmpr')">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <!-- FACTURAS CAMBIARIAS -->
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="300" tile>
          <v-list subheader two-line>
            <v-subheader inset>Factura Cambiarias</v-subheader>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-check-outline </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Pendientes de pago</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action @click="summary('fcam')">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-check-outline </v-icon>
              </v-list-item-avatar>
              <v-icon class="text-start mx-n7 mt-n10" color="primary"
                >mdi-new-box
              </v-icon>

              <v-list-item-content>
                <p class="text-start">
                  <v-list-item-title class="mx-7">Saldos de clientes</v-list-item-title>
                </p>
              </v-list-item-content>

              <v-list-item-action @click="summary('balance')">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-check-outline </v-icon>
              </v-list-item-avatar>
              <v-icon class="text-start mx-n7 mt-n10" color="primary"
                >mdi-new-box
              </v-icon>

              <v-list-item-content>
                <p class="text-start">
                  <v-list-item-title class="mx-7">Estado de Cuenta</v-list-item-title>
                </p>
              </v-list-item-content>

              <v-list-item-action @click="summary('statements')">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <!-- PROVEEDORES -->
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="300" tile>
          <v-list subheader two-line>
            <v-subheader inset>Proveedores</v-subheader>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-check-outline </v-icon>
              </v-list-item-avatar>
              <v-icon class="text-start mx-n7 mt-n10" color="primary"
                >mdi-new-box
              </v-icon>

              <v-list-item-content>
                <p class="text-start">
                  <v-list-item-title class="mx-7">Saldo Proveedores</v-list-item-title>
                </p>
              </v-list-item-content>

              <v-list-item-action @click="summary('balance_provider')">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-check-outline </v-icon>
              </v-list-item-avatar>
              <v-icon class="text-start mx-n7 mt-n10" color="primary"
                >mdi-new-box
              </v-icon>

              <v-list-item-content>
                <p class="text-start">
                  <v-list-item-title class="mx-7">Cuentas por Pagar</v-list-item-title>
                </p>
              </v-list-item-content>

              <v-list-item-action @click="summary('accounts_payable')">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <!-- CONTABILIDAD -->
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="300" tile>
          <v-list subheader two-line>
            <v-subheader inset>Contabilidad</v-subheader>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-file-check-outline </v-icon>
              </v-list-item-avatar>
              <v-icon class="text-start mx-n7 mt-n10" color="primary"
                >mdi-new-box
              </v-icon>

              <v-list-item-content>
                <p class="text-start">
                  <v-list-item-title class="mx-7"
                    >Integración de cuentas</v-list-item-title
                  >
                </p>
              </v-list-item-content>

              <v-list-item-action @click="summary('classification')">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <dtes-summary
      :visible="summaryDialog"
      :url="url"
      :title="title"
      :branch="branch"
      :business="business"
      :hiddenDate="hiddenDate"
      :period="period"
      @close="(summaryDialog = false), (hiddenDate = false), (period = false)"
    ></dtes-summary>
  </v-container>
</template>

<script>
import DtesSummary from "@/components/reports/dtes/DtesSummary.vue";
import HeaderTitle from "@/components/base/HeaderTitle";

export default {
  components: { DtesSummary, HeaderTitle },
  data: () => ({
    title: "",
    url: "",
    summaryDialog: false,
    selectedItem: 1,
    branch: false,
    business: false,
    hiddenDate: false,
    period: false,
  }),
  methods: {
    summary(item) {
      if (item == "dte") {
        this.url = "api/reports/dtes/xlsx";
        this.title = "Resumen DTEs";
        this.summaryDialog = true;
        this.business = false;
        this.branch = true;
      } else if (item == "mvnt") {
        this.url = "/api/reports/movements/xlsx";
        this.title = "Movimientos de Cuenta";
        this.summaryDialog = true;
        this.branch = false;
        this.business = false;
      } else if (item == "cmpr") {
        this.url = "api/reports/invoices/xlsx";
        this.title = "Libro de Compras";
        this.summaryDialog = true;
        this.branch = false;
        this.business = true;
        this.period = true;
      } else if (item == "fcam") {
        this.url = "api/reports/fcam/xlsx";
        this.title = "Pendientes de pago";
        this.summaryDialog = true;
        this.branch = false;
        this.business = true;
        this.hiddenDate = true;
      } else if (item == "balance") {
        this.url = "api/pdf/clientes/";
        this.title = "Saldos de clientes";
        this.summaryDialog = true;
        this.branch = false;
        this.business = true;
        this.hiddenDate = true;
      } else if (item == "statements") {
        this.url = "api/pdf/statements/";
        this.title = "Estado de Cuenta";
        this.summaryDialog = true;
        this.branch = false;
        this.business = true;
        this.hiddenDate = true;
      } else if (item == "accounts_payable") {
        this.url = "api/pdf/provider-statements/";
        this.title = "Cuentas por Pagar";
        this.summaryDialog = true;
        this.branch = false;
        this.business = true;
        this.hiddenDate = true;
      } else if (item == "balance_provider") {
        this.url = "/api/pdf/balance-provider/";
        this.title = "Saldos de Proveedores";
        this.summaryDialog = true;
        this.branch = false;
        this.business = true;
        this.hiddenDate = true;
      } else if (item == "classification") {
        this.url = "/api/pdf/classification/";
        this.title = "Integración de Cuentas";
        this.summaryDialog = true;
        this.branch = false;
        this.business = true;
        this.hiddenDate = true;
      }
    },
  },
};
</script>
