<template>
  <v-row justify="center">
    <v-dialog v-model="visible" persistent max-width="600px">
      <v-card>
        <v-form ref="myform">
          <v-card-title>
            <span class="headline">Reporte de {{ title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12" v-if="branch || business">
                  <business-name-field
                    v-model="items"
                    :returnId="!branch"
                    required
                  ></business-name-field>
                </v-col>
                <v-col cols="12" md="12" v-else>
                  <company-field
                    v-model="items"
                    @value="(val) => (items = val)"
                    required
                  ></company-field>
                </v-col>
                <v-col v-if="branch" cols="12" md="12">
                  <v-select
                    label="Sucursales"
                    :rules="onlyRequired"
                    placeholder="seleccione sucursal"
                    v-model="body.lista_sucursales"
                    :items="items"
                    item-value="id"
                    item-text="second_name"
                    attach
                    chips
                    multiple
                    required="branch"
                  ></v-select>
                </v-col>
                <v-col v-if="title == 'Estado de Cuenta' || title == 'Cuentas por Pagar'">
                  <provider-company-field
                    :business="businessId"
                    v-model="nit"
                    :isProvider="isProvider"
                    :reset="resetProviders"
                  ></provider-company-field>
                </v-col>
              </v-row>
              <v-row
                v-if="(!business && !hiddenDate) || title == 'Integración de Cuentas'"
              >
                <v-col cols="12" md="6">
                  <date-field label="Del" v-model="body.fecha_inicio"></date-field>
                </v-col>
                <v-col cols="12" md="6">
                  <date-field label="Al" v-model="body.fecha_fin"></date-field>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  title == 'Saldos de Proveedores' ||
                  title == 'Cuentas por Pagar' ||
                  title == 'Saldos de clientes' ||
                  title == 'Estado de Cuenta'
                "
              >
                <v-col cols="12" md="12">
                  <date-field
                    label="Hasta"
                    v-model="body.fecha_inicio"
                    :type="`month`"
                  ></date-field>
                </v-col>
              </v-row>
              <v-row v-if="business && !hiddenDate">
                <v-col>
                  <v-select
                    label="Año"
                    :items="uniqueYear"
                    v-model="body.year"
                    ref="ref_period"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    label="Mes"
                    :items="uniqueMonth"
                    v-model="body.month"
                    ref="ref_period"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="title == 'Integración de Cuentas'">
                <v-col>
                  <v-autocomplete
                    :label="'Clasificación'"
                    placeholder="Seleccionar una clasificación"
                    :loading="loading"
                    :items="classifications"
                    :item-text="(item) => item.name + ' - ' + item.code"
                    item-value="code"
                    v-model="body.classification_code"
                    ref="auto"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="yellow"
              @click="
                $emit('close');
                resetForm();
              "
            >
              Cerrar
            </v-btn>
            <v-btn
              :loading="loading"
              color="success"
              :disabled="!valid"
              @click="getReportXl()"
            >
              GENERAR REPORTE
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
import blobResponse from "@/utils/response.blob";

export default {
  components: {
    DateField: () => import("@/components/fields/DateField.vue"),
    BusinessNameField: () => import("@/components/fields/BusinessNameField.vue"),
    CompanyField: () => import("@/components/fields/CompanyField.vue"),
    ProviderCompanyField: () => import("@/components/fields/ProviderCompanyField.vue"),
  },
  props: {
    visible: { type: Boolean, required: true },
    url: { type: String, required: true },
    title: { type: String, required: true },
    branch: { type: Boolean, required: true },
    business: { type: Boolean, required: true },
    hiddenDate: { type: Boolean, required: false, default: false },
    period: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    isProvider: true,
    resetProviders: false,
    nit: {},
    businessId: 0,
    loading: false,
    uniqueYear: [],
    uniqueMonth: [],
    valid: false,
    isDateRangeValid: false,
    classifications: [],
    items: [],
    periods: [],
    body: {
      fecha_inicio: "",
      fecha_fin: "",
      business: [],
      lista_sucursales: [],
      year: "",
      month: "",
      nit: "",
      classification_code: "",
    },
    onlyRequired: [rules.required],
  }),
  methods: {
    getReportXl() {
      this.loading = true;
      this.valid = false;
      if (
        this.title == "Saldos de clientes" ||
        this.title == "Estado de Cuenta" ||
        this.title == "Cuentas por Pagar" ||
        this.title == "Saldos de Proveedores" ||
        this.title == "Integración de Cuentas"
      ) {
        let uri = "";
        if (
          this.title == "Saldos de Proveedores" ||
          this.title == "Cuentas por Pagar" ||
          this.title == "Saldos de clientes" ||
          this.title == "Estado de Cuenta"
        ) {
          if(this.body.fecha_inicio == null || this.body.fecha_inicio == ''){
            this.$toasted.global.info({ message: "Debe seleccionar una fecha" });
            this.loading = false;
            return;
          }
          uri =
            this.url +
            this.body.business +
            "?month=" +
            // stract the month from the date
            this.body.fecha_inicio.split("-")[1] +
            "&year=" +
            // stract the year from the date
            this.body.fecha_inicio.split("-")[0];
          if (this.title == "Cuentas por Pagar" || this.title == 'Estado de Cuenta') {
            if(this.nit.code == null || this.nit.code == ''){
              this.$toasted.global.info({ message: "Debe seleccionar un nit" });
              this.loading = false;
              return;
            }
            uri += "&nit=" + this.nit.code;
          }
        } else if (this.title == "Integración de Cuentas") {
          uri =
            this.url +
            "?businessname=" +
            this.body.business +
            "&code=" +
            this.body.classification_code +
            "&start_date=" +
            this.body.fecha_inicio +
            "&end_date=" +
            this.body.fecha_fin;
        } else {
          uri =
            this.url + "?businessname=" + this.body.business + "&nit=" + this.nit.code;
        }
        requests
          .get(uri)
          .then((response) => {
            if (response.status == 200) {
              blobResponse(response.data, "application/pdf");
              this.$toasted.global.info({ message: "Se ha generado el reporte" });
            } else if (response.status == 404) {
              this.$toasted.global.error({ message: "No se ha encontrado información" });
            }
          })
          .catch((err) => {
            console.log(err.response.status);
            this.$toasted.global.error({ message: "Ha ocurrido un error" });
          })
          .finally(() => {
            this.resetForm();
            this.$emit("close");
            this.loading = false;
          });
          this.valid = true;
      } else {
        requests.postBlob(this.url, this.body).then((response) => {
          if (response.status == 200) {
            blobResponse(
              response.data,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
            this.resetForm();
            this.$emit("close");
            this.loading = false;
            this.$toasted.global.info({ message: "El reporte fue generado" });
          } else {
            this.valid = true;
            this.$toasted.global.error();
          }
        });
      }
    },
    resetForm() {
      (this.body = {
        fecha_inicio: "",
        fecha_fin: "",
        business: [],
        lista_sucursales: [],
        year: "",
        month: "",
        nit: "",
        classification_code: "",
      }),
        this.$refs.myform.reset();
      this.valid = false;
      this.isDateRangeValid = false;
      this.nit = {};
      this.businessId = 0;
      this.resetProviders = this.resetProviders ? true : false;
    },
    getWorkingPeriod() {
      requests.get("api/accounting/working-period/").then((res) => {
        if (res.status == 200) {
          this.periods = res.data.results;
          // this.getMonths(this.periods);
        } else {
          console.error(res);
        }
      });
    },
    getClassification() {
      requests
        .get("api/accounting/classification/?code_filter__in=5,6,1,2&level=4")
        .then((res) => {
          if (res.status == 200) {
            this.classifications = res.data;
          } else {
            console.error(res);
          }
        });
    },
  },
  watch: {
    nit(oldVal, newVal) {
      if (newVal == null) {
        this.body.nit = {};
      } else {
        this.body.nit = newVal.code;
      }
    },
    title(val) {
      if (val == "Integración de Cuentas") {
        this.getClassification();
      }
      if (val == "Estado de Cuenta") {
        this.isProvider = false;
      }else if(val == "Cuentas por Pagar"){
        this.isProvider = true;
      }
    },
    items(val) {
      if (typeof val === "object") {
        this.body.business = this.items.id;
      } else {
        this.body.business = this.items;
      }
    },
    periods(period) {
      var months = [];
      var years = [];
      this.uniqueYear = [];
      this.uniqueMonth = [];
      period.forEach((element) => {
        months.push(element.month);
        years.push(element.year);
      });
      months.forEach((el) => {
        if (this.uniqueMonth.indexOf(el) === -1) {
          this.uniqueMonth.push(el);
        }
      });
      years.forEach((el) => {
        if (this.uniqueYear.indexOf(el) === -1) {
          this.uniqueYear.push(el);
        }
      });

      this.uniqueMonth.sort(function (a, b) {
        return b - a;
      });
    },
    body: {
      handler: function (value) {
        if (value.business != undefined) {
          // validate if the business is an empty array
          if (value.business.length == 0) {
           this.businessId = 0;
          } else {
            this.businessId = value.business
          }
        }
        if (value.fecha_inicio != undefined && value.fecha_inicio.length > 0) {
          if (!this.branch && !this.hiddenDate) {
            if (
              value.fecha_inicio.length === 10 &&
              value.fecha_fin != undefined &&
              value.fecha_fin.length === 10
            ) {
              if (
                new Date(value.fecha_inicio).getTime() <=
                new Date(value.fecha_fin).getTime()
              ) {
                this.valid = true;
              } else {
                this.valid = false;
                alert("El rango de fecha no es valido");
                this.body.fecha_fin = "";
              }
            }
          } else if (this.branch && !this.hiddenDate) {
            if (
              value.fecha_inicio.length === 10 &&
              value.fecha_fin != undefined &&
              value.fecha_fin.length === 10 &&
              value.lista_sucursales.length > 0
            ) {
              if (
                new Date(value.fecha_inicio).getTime() <=
                new Date(value.fecha_fin).getTime()
              ) {
                this.valid = true;
              } else {
                this.valid = false;
                alert("El rango de fecha no es valido");
                this.body.fecha_fin = "";
              }
            }
          }
        } else if (this.hiddenDate || this.period) {
          this.valid = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getWorkingPeriod();
  },
};
</script>
