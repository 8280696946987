<template>
  <div>
    <v-row>
      <v-col cols="12" md="11">
        <p class="text-h6 pt-2 ma-0 d-flex">{{ text }}</p>
      </v-col>
      <v-col cols="12" md="1" class="text-right">
        <v-btn class="mx-1" fab dark small color="indigo" :to="to">
          <v-icon dark>
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>
<script>
export default {
  props: {
    text: { type: String, required: true },
    icon: { type: String, default: "mdi-arrow-left" },
    to: {
      type: Object,
      default: () => {
        return { name: "Home" };
      },
    },
  },
};
</script>
