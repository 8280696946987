export default function blobReponse(blob, applicationType) {
  var newBlob = new Blob([blob], { type: applicationType });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.target = "_blank";
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 100);
}
